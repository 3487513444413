.container-layout a {
  color: #000000 !important;
}
.ant-layout-content {
  margin: 0 16px;
}
.container-layout a:hover {
  color: #166c29;
}
.ant-layout {
  width: 100%;
  /* height: 100vh; */
}
.login_header {
  background-color: #ffffff;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  /* margin-top: 20px; */
  padding-left: 30px;
  padding-right: 30px;
}
.col_header_login {
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  min-width: 120px;
  margin-right: 6px;
  height: 100%;
  max-width: 46% !important;
  cursor: pointer;
}

.ant-typography.ant-typography-success {
  color: #29aa46 !important;
}

.ant-typography .ant-typography-warning {
  color: #f7b836 !important;
}

.col_header_login:hover {
  background-color: rgba(41, 170, 70, 0.2);
  border-bottom: 2px solid #1e8f3a;
}
.active_menu {
  background-color: rgba(41, 170, 70, 0.2);
  border-bottom: 2px solid #1e8f3a;
}
/* .col_login {
    background: linear-gradient(81.62deg, #1ed251 2.25%, #29aa46 79.87%);
    color: #ffffff;
    border-radius: 20px;
    text-transform: uppercase;
    border: none;
    font-weight: 600;
    font-size: 14px;
    width: 150px;
    height: 35px;
    letter-spacing: 0.2px;
} */
.btn_login:hover {
  background-color: #1e8f3a;
  color: #ffffff;
}
.btn_login {
  background: linear-gradient(81.62deg, #1ed251 2.25%, #29aa46 79.87%);
  color: #ffffff;
  border-radius: 20px;
  text-transform: uppercase;
  border: none;
  font-weight: 600;
  font-size: 14px;
}
.btn_login:hover {
  background-color: #1e8f3a;
  color: #ffffff;
}
.modal-login {
  padding-bottom: 0px;
  border-radius: 15px;
}
.modal-login .ant-modal-footer,
.modal-login .ant-modal-close {
  display: none;
}
.logo {
  background-repeat: no-repeat;
  padding-right: 30px;
  height: 64px;
}
.logo img {
  max-width: 100%;
  max-height: 100%;
}
.bg_xts {
  background-image: url("../public/images/img-bg.png");
  background-repeat: no-repeat;
  vertical-align: text-top;
  background-color: white;
  min-height: 100vh;
  padding-top: 20px;
  background-position: right;
}
.title {
  font-weight: bold;
  border-radius: 15px;
  border-bottom: 6px solid #1e8f3a;
  width: 260px;
  font-size: 36px;
}

.title_contact {
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 50px;
}
.logo-modal {
  display: flex;
  justify-content: center;
}
.welcome {
  color: #29aa46;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  padding: 10px;
  margin: 15px 0;
}
.form-login-username .ant-form-item-control {
  max-width: 100%;
}
.form-login-username .ant-form-item-control input {
  border-radius: 15px;
}
.form-login-password .ant-form-item-control {
  max-width: 100%;
}
.form-login-password .ant-form-item-control .ant-input-password {
  border-radius: 15px;
  height: 50px;
}
.btn-login {
  background: linear-gradient(90deg, #26bd4c 0%, #20df56 100%);
  border-radius: 12px;
  border: none;
  text-transform: uppercase;
  width: 100%;
  height: 45px;
  font-size: 14px;
  font-weight: 700;
}
.shadow-form-search .ant-row {
  margin-bottom: 40px;
}
.btn-login:hover {
  background: #29aa46;
}
.modal-login .ant-switch-checked {
  background-color: #29aa46;
}
.remember {
  margin: 20px 0;
}
.title_detail {
  text-transform: uppercase;
  padding-left: 30px;
  font-weight: bold;
}
.main-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
}
.input-login {
  height: 50px;
}

.ant-notification-notice {
  border-radius: 15px;
}
.line {
  border: 1px solid #29aa46;
  margin: 30px 0;
}

.content-login {
  text-align: justify;
}
.title-form-search {
  font-size: 32px;
  color: #29aa46;
  font-weight: bold;
  margin: 40px 0;
}

.shadow-form-search {
  padding: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 15px;
  background-color: white;
  text-align: center;
  height: 384px;
  width: 485px;
  max-width: calc(100% - 20px);
}
.btn-search {
  background: #29aa46;
  color: #ffffff;
}
.filter {
  margin: 27px auto auto 21px !important;
  width: 100%;
}
.filter .ant-select,
.filter .ant-input {
  width: 100%;
  border-radius: 15px !important;
}

.container-menu-left .ant-menu-item-icon,
.icon-nav,
.icon-nav-info {
  color: #29aa46;
  background: #fff;
  border-radius: 10px;
  font-size: 24px;
  padding: 5px;
  line-height: 24px;
  position: relative;
  top: 6px;
}

.icon-nav-home {
  color: #fff;
  background: #29aa46;
  border-radius: 10px;
  font-size: 24px;
  padding: 5px;
  line-height: 24px;
  position: relative;
  top: 6px;
}
.info-nav {
  padding-left: 50px;
}
.icon-nav-info {
  font-size: 25px;
}

.btn_tc {
  height: 50px;
  font-size: 14px;
  font-weight: 700;
  background: linear-gradient(90deg, #26bd4c 0%, #20df56 100%);
}
.menu_xts .menu_logo {
  font-size: 20px;
  font-weight: bold;
}
.modal-reg .title-reg {
  text-align: center !important;
  font-size: 18px !important;
  color: #29aa46 !important;
  font-weight: bold !important;
}
.modal-reg .content .input-reg {
  margin: 6px 0;
  border-radius: 15px;
}
.modal-reg .ant-modal-content {
  padding: 35px;
}
.modal-reg .ant-select-selector {
  border-radius: 15px !important;
}

.ant-form-item-control-input-content .ant-select .ant-select-selector {
  border-radius: 15px;
}
.ant-radio-inner::after {
  background-color: #29aa46 !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #29aa46 !important;
}
.ant-form-item-tooltip {
  color: #29aa46 !important;
}
::placeholder {
  font-style: italic;
}
.ant-select-selection-placeholder {
  font-style: italic;
}
.is-fullwidth {
  width: 100% !important;
}
.text_gr {
  color: #29aa46;
}
.text_yl {
  color: #f7b836;
}
.info_XTS {
  text-align: center;
}
.page_contact {
  margin-top: 12%;
}
.logo-menu-square {
  display: none;
}
@media (max-width: 1600px) {
  .logo-menu-square {
    display: initial;
    margin: 0 10px;
  }
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #c8ebd0 !important;
}
.upload-list-inline {
  overflow: hidden;
}
.upload-list-inline .ant-upload-list {
  overflow: hidden;
  margin-left: 120px;
  margin-top: -40px;
  min-height: 40px;
}
.upload-list-inline .ant-upload-list .ant-upload-list-picture-container {
  float: left;
  margin-left: 5px;
}
.upload-list-inline
  .ant-upload-list
  .ant-upload-list-picture-container
  .ant-upload-list-item-name {
  display: none;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: 48px;
  height: auto;
}
.ant-row-button label {
  display: none;
}
@media (min-width: 700px) {
  .ant-select.ant-select-in-form-item,
  .kt-search-form .ant-form-item {
    width: 200px;
  }
  .ant-modal-body .ant-select.ant-select-in-form-item,
  .ant-modal-body .kt-search-form .ant-form-item {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .ant-space-align-center {
    padding-top: 3px;
  }
  .ant-select.ant-select-in-form-item,
  .kt-search-form .ant-form-item {
    width: 100%;
  }
  .ant-form-horizontal {
    padding: 0px !important;
  }
}
.ql-toolbar.ql-snow {
  border-radius: 15px 15px 0px 0px;
  border: 1px solid #d9d9d9 !important;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-radius: 0px 0px 15px 15px;
  border: 1px solid #d9d9d9 !important;
  border-top: 0px !important;
}
.ant-layout-sider-collapsed .supporter {
  display: none;
}
.supporter {
  text-align: center;
  position: fixed;
  bottom: 0px;
  padding: 0px 0px 13px 13px;
}
.supporter .group {
  background-color: #29aa46;
  width: 220px;
  border-radius: 15px;
  color: #fff;
  text-align: left;
  padding: 20px;
  font-weight: 600;
  font-size: 12px;
}
.supporter-icon {
  background-color: #fff;
  border-radius: 12px;
  width: 40px;
}
.supporter-title {
  margin-top: 10px;
}
.supporter-question,
.supporter-hotline {
  margin-top: 10px;
  background-color: #fff;
  color: #2d3748;
  text-align: center;
  border-radius: 8px;
  padding: 5px 0px;
  text-transform: uppercase;
}
.supporter-hotline {
  margin-top: 10px;
}
.info-qrcode {
  border: 1px solid rgb(214, 214, 214);
  border-radius: 10px;
  padding: 10px;
}
.info-qrcode .info-date > div {
  padding: 30px 0px;
}
.info-qrcode .upload-image {
  text-align: center;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 10px;
  padding: 55px 0px;
}
.qrcode {
  display: block;
  position: relative;
  background: rgb(255, 255, 255);
  text-align: center;
  width: 100%;
  float: left;
  font-weight: bold;
  overflow: hidden;
}
.qrcode-left {
  display: block;
  position: absolute;
  transform: rotate(270deg);
  top: 0px;
  width: 100%;
  height: 100%;
  font-size: 18px;
  right: 10px;
}
.qrcode-img {
  padding: 0px 20px 20px 20px;
}
.qrcode-img span {
  font-size: 160px;
}
.qrcode-bottom {
  display: block;
  position: absolute;
  bottom: 0px;
  width: 100%;
  font-size: 18px;
}
.qrcode-bottom p {
  margin-bottom: 0px;
}
.qrcode-right {
  display: block;
  position: absolute;
  transform: rotate(90deg);
  top: 0px;
  width: 100%;
  height: 100%;
  font-size: 18px;
  left: 15px;
}
.qrcode-right p {
  transform: rotate(180deg);
}
.container-qr-code {
  font-size: 16px;
}
.container-qr-code span.boder {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 3px;
  margin-bottom: 0px;
  font-weight: 700;
  z-index: 99;
  position: relative;
}
.stamp_label_logo_or_date .ant-form-item-label > label[title="tem"],
.stamp_label_product_id label {
  display: none;
}
.mb-3 {
  margin-bottom: 3px;
}
