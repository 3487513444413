.ant-layout-content {
  background-color: #ffffff;
  border-radius: 15px;
}
.ant-page-header {
  background: none;
}
.ant-page-header-heading-extra > *:last-child {
  margin-right: 0;
}
.user_title {
  margin: 27px auto auto 21px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
.user_filter {
  width: 100%;
  padding: 0 15px;
}
.user_filter_col {
  /* padding: 0 50px !important; */
  margin: 15px 25px;
}
.user_filter_select .ant-select-selector {
  width: 320px;
  height: 90px;
  border-radius: 15px !important;
}

.user_table {
  width: 100%;
  margin-top: 15px;
  padding: 0 15px;
}

.ant-select .ant-select-selector {
  border-radius: 15px !important;
}
.btn_primary {
  background-color: #29aa46 !important;
  border-radius: 12px !important;
  color: #ffffff !important;
  border: none !important;
  width: 115px;
  height: 100%;
}

.btn_primary_footer {
  background-color: #29aa46 !important;
  border-radius: 12px !important;
  color: #ffffff !important;
  border: none !important;
  width: 117px;
  height: 30px;
  margin-right: 15px;
  margin-top: 30px;
}

.btn_primary_confirm {
  background-color: #29aa46 !important;
  border-radius: 12px !important;
  color: #ffffff !important;
  border: none !important;
  width: 100px;
  height: 30px;
  margin-right: 20px;
}

.btn_primary_add {
  background-color: #29aa46 !important;
  border-radius: 12px !important;
  color: #ffffff !important;
  border: none !important;
  width: 117px;
  height: 30px;
  margin-right: 15px;
}

.btn_primary_edit {
  background-color: #29aa46 !important;
  border-radius: 12px !important;
  color: #ffffff !important;
  border: none !important;
  width: 65px;
  height: 30px;
}
.user_table_content .text_yl {
  color: #f7b836 !important;
}
.newStyle {
  border-radius: 20px !important;
}
.newStyle .ant-modal .ant-modal-content,
.newStyle .ant-modal .ant-modal-header {
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
}
.ant-modal-centered .ant-modal {
  border-radius: 20px !important;
}
.modal_detail .ant-modal-content {
  border-radius: 20px !important;
  /* width: 728px; */
  height: auto;
}

.modal_detail_parameter .ant-modal-content {
  border-radius: 20px !important;
  /* width: 728px; */
  height: auto;
}

.modal_edit_parameter .ant-modal-content {
  border-radius: 20px !important;
  width: 671px;
  height: auto;
}

.modal_add .ant-modal-content {
  border-radius: 20px !important;
  width: 728px;
  height: auto;
}

.modal_add_confirm .ant-modal-content {
  border-radius: 20px !important;
  width: 728px !important;
  height: auto;
}

.modal_add_parameter .ant-modal-content {
  border-radius: 20px !important;
  width: 728px;
  height: auto;
}
.detail_info .field {
  display: flex;
}
.span_content {
  display: flex;
  align-items: center;
  width: 240px !important;
  /* display: inline-block !important; */
  font-weight: bold;
  margin-left: 42px;
}
.user_filter_col .ant-input {
  border-radius: 15px;
  height: 40px;
  width: 320px;
}
.detail_info {
  margin: 16px 55px;
  margin-left: 0 !important;
  /* padding-bottom: 35px; */
  /* border-bottom: 3px solid #29aa46; */
  /* margin-bottom: 25px; */
}

.text_input {
  width: 337px;
  height: 30px;
  border-radius: 15px;
}

.text_search_code {
  width: 100%;
  height: 50px;
  border-radius: 15px;
  font-size: 16px;
}
.area {
  width: 337px;
  border-radius: 15px;
}
.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #e3e3e3;
}

.modal_add_parameter .ant-select,
.modal_edit_parameter .ant-select {
  width: 337px;
  border-radius: 15px;
}

.modal_add_parameter .ant-form-item {
  margin-bottom: 10px;
}

.content_confirm {
  font-weight: 600;
  font-size: 16px;
  margin-left: 30px;
}

.btn_confirm,
.btn_footer {
  margin-top: 40px;
}

.form_input .ant-form-item-label {
  text-align: left;
  margin-left: 80px;
  font-weight: 600;
}

.modal_add_user_group .ant-modal-content {
  width: 728px;
  height: auto;
}

.detail-for-control .ant-form-item {
  margin-bottom: 5px !important;
}
