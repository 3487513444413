.modal_detail_function_pack .ant-modal-content {
  width: 763px;
  height: auto;
}

.icon_delete {
  font-size: 15px;
  color: red;
}
.text-align-right {
  text-align: right !important;
}
.margin-top-15 {
  margin-top: 15px;
}
