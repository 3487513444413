.ant-layout-sider {
  background-color: #ffffff;
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
}
.ant-layout-header {
  background-color: #f3f3f3;
  margin: 16px;
  padding: 0;
}

.container-menu-left {
  width: 100%;
  border-radius: 20px !important;
}
.menu {
  border-radius: 20px !important;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.2);
  padding: 0px 20px 0px 0px;
}
.menu a {
  color: #808a97 !important;
}
.sub-menu {
  display: none;
}
.ant-menu-item-selected {
  color: #000000 !important;
}
.ant-menu-root {
  height: auto !important;
}
.ant-menu-submenu:not(.ant-menu-overflow-item-rest)
  div:not(.ant-divider)::after {
  content: "";
  width: 1px;
  height: 30px;
  background: linear-gradient(81.62deg, #1ed251 2.25%, #29aa46 79.87%);
  position: absolute;
  left: -8px;
  top: 15px;
}
/* .container-menu-left .main-menu-open ul li:first-child::after {
    content: '' !important;
    width: 1px !important;
    height: 30px !important;
    background: linear-gradient(81.62deg, #1ed251 2.25%, #29aa46 79.87%);
    position: relative;
    right: -20px;
    top: 11px;
    bottom: 0;
    display: inline-block;
    left: unset;
} */
.ant-menu-item,
.ant-menu-submenu {
  color: #808a97 !important;
}
/* .container-menu-left .main-menu-open {
    overflow-x: scroll;
} */
.container-menu-left
  .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-selected:last-child {
  color: #000000 !important;
  /* background: rgba(41, 170, 70, 0.2); */
  /* border-radius: 0px 20px 20px 0px !important; */
}
.container-menu-left
  .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-selected {
  color: #000000 !important;
  /* background: rgba(41, 170, 70, 0.2); */
}
.container-menu-left
  .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-submenu {
  padding: 0 8px !important;
}
.container-menu-left
  .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-selected:first-child,
.container-menu-left
  .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item:first-child:hover {
  border-radius: 20px 0 0px 20px !important;
}
.container-menu-left
  .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-selected,
.container-menu-left
  .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item:hover {
  color: #000000 !important;
  background: rgba(41, 170, 70, 0.2);
}
.ant-drawer-body .ant-menu-inline,
.ant-drawer-body .ant-menu-vertical,
.ant-drawer-body .ant-menu-vertical-left {
  border-right: 0px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-color: #29aa46 !important;
}
.ant-menu-submenu-popup .ant-menu-sub {
  /* .ant-menu-sub { */
  display: flex;
  border-radius: 0 0 25px 25px !important;
  background: #c8ebd0 !important;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.2) !important;
  align-items: baseline;
}
.menu-sub-divider {
  height: 30px !important;
  position: relative;
  left: 18px;
  background: #29aa46;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #c8ebd0 !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-open,
.ant-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-open
  .ant-menu-sub.ant-menu-inline {
  background-color: #fff;
}
.ant-menu-submenu-selected span.ant-menu-title-content {
  color: #000000 !important;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active,
.ant-menu-submenu-placement-bottomLeft .ant-menu-item:active,
.main-menu-open .ant-menu-item:active,
.main-menu-open .ant-menu-submenu-title:active {
  background: #c8ebd0 !important;
}

/* .container-menu-left .ant-menu-item-active .ant-menu-item-icon, */
/* .container-menu-left .ant-menu-submenu-active .ant-menu-item-icon, */
.container-menu-left .ant-menu-item-selected .ant-menu-item-icon,
.container-menu-left .ant-menu-submenu-selected .ant-menu-item-icon {
  background: #29aa46 !important;
  color: #fff !important;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 44px;
}
/* .ant-menu-horizontal > .ant-menu-item:not(:first-child)::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    right: 0;
    left: 0;
  } */
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom-color: #29aa46;
}
.ant-menu-submenu-popup .ant-menu-sub {
  margin-top: -7px !important;
  margin-left: -8px !important;
}
.ant-menu-submenu-popup::before {
  display: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  background-color: rgba(41, 170, 70, 0.2);
}
