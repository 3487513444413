.ant-modal {
  max-width: calc(84%);
}

.ant-modal-title {
  position: relative;
  top: 3px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  font-size: normal;
  /* padding-left: 30px; */
}

.ant-modal-body {
  padding: 16px 20px 4px 25px;
  max-height: 60vh;
  overflow-y: auto;
}

.modal-login .ant-modal-body {
  max-height: none;
}

.ant-modal-header {
  background-color: #29aa454f;
}

.ant-modal-close-x {
  margin-top: 3px;
}

.ant-modal-footer {
  display: none;
}

.kt-modal .ant-modal-footer {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  display: block !important;
}

.ant-modal-content {
  border-radius: 15px;
}

.ant-modal-header {
  border-radius: 15px 15px 0 0;
}

.ant-modal.kt-modal-no-form .ant-modal-body {
  padding-bottom: 20px;
}

.ant-modal.kt-modal-header-center .ant-modal-header {
  text-align: center;
}

.ant-modal.kt-modal-no-header-bg .ant-modal-header {
  background-color: #fff;
}

.ant-modal-footer {
  border-top: none;
}
