.kt-title {
  text-transform: uppercase;
}

h5.kt-title {
  text-transform: uppercase;
  font-size: 14px;
}

.kt-title-section {
  margin-top: 24px;
}
