.kt-confirm .ant-modal-close-x {
  margin-top: 0px !important;
}

.kt-confirm .ant-modal-body {
  padding: 16px 20px 8px 50px;
  font-size: 16px;
  color: #000;
}
.kt-confirm .ant-modal-footer {
  display: block;
  padding-bottom: 18px;
}
.kt-confirm .ant-modal {
  top: -100px;
}
