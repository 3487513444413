* {
  margin: 0;
  padding: 0;
}
body {
  /* font-family: Montserrat, Arial, sans-serif !important; */
  background-color: #f3f3f3;
  font-family: "Montserrat";
  font-size: 12px;
}
a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #29aa46 !important;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #29aa46;
  pointer-events: none;
}
.kt-info-header.title-name .ant-page-header-heading-title {
  border-bottom: 2px #29aa46 solid;
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ant-btn-link {
  color: #29aa46;
}
.mb-3 {
  margin-bottom: 3px;
}
.mb-5 {
  margin-bottom: 5px;
}
.text-transparent label {
  opacity: 0;
}
.dml-30 {
  margin-left: 30px;
}
.list-images {
  float: left;
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ccc;
}
@media (max-width: 767.98px) {
  .dml-30 {
    margin-left: 0;
  }
  .w100 {
    width: 100% !important;
  }
}
