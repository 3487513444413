.ant-page-header {
  padding: 0;
  font-size: 18px;
}
.ant-page-header-heading-title {
  text-transform: uppercase;
}
.ant-page-header-heading-extra > *:last-child {
  margin-right: 0;
}
.ant-page-header-heading-extra {
  margin: 0;
}
