.ant-layout-content {
  background-color: #ffffff;
  border-radius: 15px;
  flex: none;
}
.ant-layout-content {
  padding: 16px 20px;
}

@media (max-width: 767.98px) {
  .ant-layout-content {
    margin: 0 8px;
  }
  .container-menu-right {
    flex: 0 1 200px !important;
  }
  .container-menu-left {
    max-width: calc(100% - 200px) !important;
  }
  .ant-layout-header {
    margin: 0px 12px !important;
  }
}
