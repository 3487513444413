.ant-input.ant-input,
.ant-picker {
  border-radius: 15px;
}
.ant-input-affix-wrapper {
  border-radius: 15px;
  /* padding: 6.5px 15px; */
  padding-left: 15px;
  padding-right: 15px;
}
.ant-input-affix-wrapper .ant-input {
  border-radius: 0px;
}
.ant-form-item-label {
  text-align: left;
}
.ant-form-item-label > label {
  font-weight: normal;
}
.ant-form-item {
  margin-bottom: 6px;
  margin-top: 3px;
}
.ant-form-item-label > label::after {
  margin: 0 8px 0 0px;
}
/* Useful */
.kt-form-item-display-as-text .ant-select-selector,
.kt-form-item-display-as-text .ant-input {
  border: none !important;
  background: none !important;
  cursor: text !important;
  color: #000000 !important;
}

.kt-form-item-display-as-text .ant-select-disabled .ant-select-arrow {
  display: none;
}

.kt-search-form .ant-form-item {
  margin-bottom: 0px;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  position: absolute !important;
  right: -6px !important;
  top: 8px !important;
  color: #ff4d4f !important;
}

.modal-login
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  right: -14px !important;
}
@media (max-width: 576px) {
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    position: unset !important;
  }
  .form-section {
    padding-left: 0;
  }
}
