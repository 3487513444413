.ant-btn {
  border-radius: 15px;
  padding: 3px 15px;
}
.ant-btn-success,
.ant-btn-success:active {
  background-color: #29aa46;
  color: white !important;
  border-color: #29aa46;
}

.ant-btn-success:hover,
.ant-btn-success:focus {
  background-color: #49b85e;
  color: white;
  border-color: #49b85e;
}
.ant-btn-success-gradient,
.ant-btn-success-gradient:active {
  border: none;
  color: white;
  background-color: #29aa46;
  background-image: linear-gradient(180deg, #26bd4c 0%, #20df56 100%);
  border-radius: 12px;
  transition: background-color 0.8s ease 0s;
}

.ant-btn-success-gradient:hover,
.ant-btn-success-gradient:focus {
  color: white;
  background-color: #20df56;
}

.ant-btn.btn-largest {
  height: 45px;
  font-size: 16px;
  font-weight: 700;
  line-height: 43px;
}
