input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
.ant-input-number {
  border-radius: 15px;
}

.ant-input-number-group > .ant-input-number:last-child,
.ant-input-number-group-addon:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.ant-input-number-handler-wrap {
  background-color: transparent;
}
