.frontend-menu {
  height: 293px;
  background-image: url("../../public/images/banner.png");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  padding: 10px 20px;
  width: 1180px;
  margin: auto;
}
.card {
  border-radius: 5px;
  background-color: #ffffff;
  margin: 0px 10px;
  padding: 20px;
}
.menu-header {
  background-color: #ffffff;
  box-shadow: 0px 3.5px 5.5px rgb(0 0 0 / 20%);
  border-radius: 20px;
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 100%;
  flex: 0 0 100%;
  height: 64px;
}
.page-content {
  width: 1280px;
  margin: auto;
  margin-top: 20px;
}
.customer-info-text {
  margin-left: -20px;
  margin-bottom: 30px;
}
.customer-info-text span {
  background-color: #29aa468c;
  padding: 5px 20px;
}
.customer-info-name {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.news {
  border-bottom: 1px solid #166c29;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.news-text {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
}
.news-date {
  padding-top: 10px;
  color: #ccc;
  font-size: 11px;
}
.news-title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 10px;
}
.news-link {
  margin-top: 20px;
}
.news-link a {
  border-radius: 5px;
  border: 1px solid #29aa46;
  color: #29aa46;
  padding: 5px 10px;
  text-transform: uppercase;
}
.customer-property {
  padding: 10px 50px;
}
.customer-name {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.carousel-item {
  padding: 0px 20px;
}
.react-multi-carousel-list {
  padding-bottom: 40px;
}
.react-multi-carousel-list img {
  width: 100%;
}
.text-center {
  text-align: center;
}
.background {
  background-image: url("../../public/images/img-bg.png");
  background-repeat: no-repeat;
  background-position: bottom right;
}

.col_header {
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  min-width: 120px;
  margin-right: 6px;
  height: 100%;
  max-width: 46% !important;
  cursor: pointer;
}

.col_header a {
  color: rgba(0, 0, 0, 0.85);
}
.title-product {
  margin: 20px -20px 20px -20px;
  border-bottom: 1px solid #1ed251;
}
.title-product span.ant-page-header-heading-title {
  background-color: #29aa468c;
  padding: 0px 20px;
  border-radius: 5px 5px 0px 0px;
}
.page-content img {
  max-width: 100%;
}
@media (max-width: 1280px) {
  .frontend-menu {
    width: 1280px;
  }
}

@media (max-width: 767.98px) {
  .menu-header {
    background: transparent;
    box-shadow: initial;
  }
  .frontend-menu {
    height: 100px;
  }
  .menu-header .ant-col {
    display: none;
  }
  .col_header {
    padding: 0px 5px;
  }
  .frontend-menu {
    padding: 0px;
  }
  .frontend-menu,
  .page-content {
    max-width: 100%;
    width: 100%;
  }
  .card {
    margin-left: 0px;
    padding: 10px;
  }
  .background {
    min-width: initial;
  }
  .customer-property {
    padding: 0px 0px;
  }
}
