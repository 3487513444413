.form-login .ant-form-item-label > label {
  font-weight: 500;
  font-size: 16px;
}

@media (min-width: 1200px) {
  .col_header_login {
    min-width: 160px;
  }
}

@media (max-width: 991.98px) {
  .title_contact {
    font-size: 18px;
  }
}

@media (max-width: 767.98px) {
  .login_header {
    background: none;
    box-shadow: none;
  }
  .title_contact {
    font-size: 24px;
  }
  .col_header_login {
    margin-top: 8px;
    border-radius: 4px;
  }
  .btn-login-menu {
    margin-top: 10px;
  }
}

@media (max-width: 575.98px) {
  .btn-login-menu {
    position: fixed;
    top: 30px;
  }
  .title_contact {
    font-size: 18px;
    padding: 20px;
  }
}
