.title_page {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
}
.user_header_page {
  display: block !important;
}
.user_filter {
  align-items: center;
  padding: 0px;
}
.user_filter .ant-col {
  margin-right: 25px !important;
}
.user_filter .ant-select {
  width: 100%;
}
.user_filter .ant-select .ant-select-selector {
  border-radius: 15px !important;
}
.user_filter input {
  border-radius: 15px !important;
}
.btn-search {
  background: #29aa46;
  border-radius: 12px;
  color: #ffffff;
  border: none;
}
.btn-search:hover {
  background: #29aa46;
}
.user_content {
  margin: 10px 0;
}
.table {
  width: 100%;
}
.add_user .ant-form-item {
  margin-bottom: 15px !important;
}
.add_user .ant-input {
  border-radius: 15px;
}
.add_user .ant-select .ant-select-selector {
  margin: 0px !important;
}
.add_user .ant-modal-content {
  width: 730px;
  height: auto;
}
.full-width .ant-space-item .ant-row .ant-col .ant-form-item-required {
  font-weight: normal;
}
