.ant-table-thead > tr > th {
  color: #000000;
  font-weight: 600;
}

.ant-table:not(.kt-info-table).ant-table-tbody > tr:nth-child(even) td {
  background-color: #000000;
}

.ant-table tfoot > tr > td,
.ant-table tfoot > tr > th,
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  padding: 10px;
  white-space: nowrap;
}
.table-list .ant-table-tbody > tr:nth-child(2n + 1) {
  background-color: #ececec;
}
.ant-table-tbody {
  background-color: white;
}
