.detail-customer .name-customer {
  font-size: 18px;
}
.detail-customer .header-detail {
  border-radius: 15px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.detail-customer .content-detail {
  border-radius: 15px;
  padding: 10px;
  background-color: white;
  margin-top: 20px;
}
.detail-customer .content-tabs {
  width: 100%;
}
.detail-customer .content-info button {
  margin: 0 15px;
}
.detail-customer .ant-card {
  border: none;
}
.detail-customer .ant-card-head {
  padding: 0 !important;
  font-size: 14px;
  border-bottom: 0;
}
.detail-customer .ant-card-body .ant-card-grid {
  font-size: 14px;
  border: none;
  width: 100%;
}
