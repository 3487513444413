.menu_xts {
  float: right;
  margin-top: 35px;
  margin-right: 10px;
}
.ant-layout-sider-collapsed .menu_xts {
  margin-right: 15px;
}
.logo_xts {
  width: 100%;
  margin-top: 25px;
  text-align: center;
}
.ant-layout-sider .logo-menu-square {
  visibility: hidden;
}
.ant-layout-sider-collapsed .logo-menu-rectangle,
.ant-layout-sider-collapsed .logo-menu-border {
  display: none;
}
.ant-layout-sider-collapsed .logo-menu-square {
  visibility: visible;
  margin-bottom: 30px;
}
.ant-layout-header {
  background: transparent !important;
}
.ant-btn.menu-drawer {
  padding: 3px 10px;
}
