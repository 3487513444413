.kt-info-header .ant-page-header-heading-title {
  font-size: 16px;
  font-weight: bold;
  /* padding-left: 30px; */
}

.kt-info-header .ant-page-header-heading-left {
  margin: 0px;
}

.kt-info-header-has-border-top .ant-page-header-heading::before {
  content: "";
  width: 100%;
  height: 1px;
  background: linear-gradient(81.62deg, #1ed251 2.25%, #29aa46 79.87%);
  margin-bottom: 8px;
}
